.links {
	font-family: Camphor,Open Sans,Segoe UI,sans-serif;
	font-size: 1.2em;
	text-align: center;
	margin-top: 150px;
	padding-bottom: 200px;
	justify-content: center;

	a {
		margin-right: 2%;
		text-decoration: underline;
		// font-weight: bold;
		color: #68779d;
		font-size: 1.5em;
		margin-left: 2%;
		display: inline-block;
	}
  
  /* visited link */
  a:visited {
		color: #68779d;
  }
  
  /* mouse over link */
  a:hover {
		color: #747fe0;
		text-decoration-style: double;
  }
  
  /* selected link */
  a:active {
		color: #000000;
	}
	
	div {
		margin-bottom: 3%;
	}
}
  