p {
	margin-bottom: 30px;
	line-height: 27px;
	color: #525f7f;
	font-size: 1.2em;
}
.date, .employer{
	color: #525f7f;
	font-style: italic;
	font-size: 1em;
}

h3 {
	font-weight: 500;
	font-size: 1.8em;
	line-height: 38px;
	color: #525f7f;
	margin-top: 0;
	margin-bottom: 0px;
}
.calendar-icon {
	width: 1.3em;
	vertical-align: text-bottom;
	margin-right: 0.5em;
}
