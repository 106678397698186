.projects {
	font-family: Camphor,Open Sans,Segoe UI,sans-serif;
  
  }
  
  .project {
  
	position: relative;
	// display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-bottom: 60px;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 50px 100px rgba(50,50,93,.05), 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.1);
	overflow: hidden;
  
	width: 100%;
	padding: 35px;
	box-sizing: border-box;
  


  
	.technologies {
	  display: flex;
	  flex-wrap: wrap;
  
	  span {
		border-radius: 4px;
		border: 2px solid #92eac0;
		padding: 8px;
		margin-right: 10px;
		margin-bottom: 10px;
		font-family: Camphor,Open Sans,Segoe UI,sans-serif;
		font-size: .85em;
		color: #525f7f;
	  }
	}
  }