body {
  background-color: #f6f9fc;
}

.App {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 40px;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 0 15px;    
  }
}

.header {
  font-family: Monaco,Consolas,Lucida Console,monospace;
  font-size: 3em;
  color: #747fe0;
  margin-bottom: 7%;
}
.intro > p{
  font-family: Camphor,Open Sans,Segoe UI,sans-serif;
  font-weight: 400;
  line-height: 40px;
  color: #525f7f;
  margin: 80px 0;
}

.intro {
    font-family: Camphor,Open Sans,Segoe UI,sans-serif;
    font-weight: 400;
    font-size: 1.3em;
    line-height: 40px;
    color: #525f7f;
    margin: 80px 0;
}

.intro-sub {
    font-family: Camphor,Open Sans,Segoe UI,sans-serif;
    font-weight: 400;
    font-size: 1.3em;
    line-height: 40px;
    color: #525f7f;
}


h2 {
  color: #525f7f;
  font-family: Camphor,Open Sans,Segoe UI,sans-serif;
  font-weight: 500;
  font-size: 1.5em;
  text-align: center;
  padding-top: 50px;
  margin-top: 50px;
  margin-bottom: 80px;
}

h2:after {
  content: " ";
  border: 4px solid #747fe0;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  width: 50px;
}

.face {
  border-radius: 50%;
  width: 20%;
  display: block;
  margin: 0 auto;
  margin-top: 6em;
  margin-bottom: 6em;
  box-shadow: rgba(0,0,0,1) 0 0 10px;
  @media (min-width: 320px) and (max-width: 480px) {
    width: 45%;
    margin-top: 4em;
  }
  @media (min-width: 481px) and (max-width: 1000px) {
    width: 30%;
    margin-top: 4em;
  }
}

.face:hover {
  -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
  filter: blur(5px);
}

.intro-sub > h3 { 
  color: #747fe0;; 
  font-family: 'Droid serif', serif; 
  font-size: 1.3em;
  font-weight: 400; 
  font-style: italic; 
  line-height: 44px; 
  // margin: 0 0 12px; 
  // text-align: center; 
}